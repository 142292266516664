import React, { useState, useEffect, Fragment } from "react";
import Head from 'next/head'
import HomeBanner from '@/components/banners/Home'
import HomeCategories from "@/components/HomeCategories";
import axios from "axios";
import { nanoid } from "nanoid";
import PageLoader from '@/components/loader'
import { Skeleton, Container } from "@mui/material";


export default function Home() {
  const [banners, setBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);

  useEffect(()=>{
    fetchAllCategories();
    fetchBanners('home');
  },[]);

  const fetchAllCategories = async() => {
    await axios.get(`/api/categories/listAll`,{
      headers:{
        'x-auth-token':process.env.JWT_TOKEN
      }
    }).then((response)=>{
      setCategories(response.data.result ? response.data.result : []);
      setLoader(false)
    })
  }

  const fetchBanners = async(page) => {
    await axios.get(`/api/banners?page=${page}`, {
        headers: {
          "x-auth-token": process.env.JWT_TOKEN,
        },
      })
      .then((response) => {     
        setBanners(response.data.result ? response.data.result : [])
        setLoading(false)
      })
      .catch(err => {
        console.log(err);
      })
  }

  return (
    <Fragment>   
      <Head>
        <title>Taste of Telugu | Home</title>              
      </Head>
      {loader ? (
        <PageLoader />
      ):(
        <>
          {loading ? (
            <Skeleton animation={'wave'} variant="rectangular" height={'90vh'} width={'100%'} sx={{mt:4}}/>
          ):(
            <HomeBanner banners={banners}/>
          )}
          {categories && categories.map((item, index)=>(
            <>
              {item.title && item.title.toLowerCase() && (
                <HomeCategories data={item} key={nanoid(40)} type={(index+1)%2 == 0 ? 1 : 2} />
              )}
            </>
          ))}
        </>
      )}
    </Fragment>
  )
}
